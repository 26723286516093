import { Space, Tag } from "antd"
import { useState } from "react"
import "./style.css"
import { CheckCircleIcon } from "@/components/icons"
const { CheckableTag } = Tag

type tagsItem = {
  id: string | number
  title: string
}
interface Props {
  tags: tagsItem[]
  handleSelect?: (items?: string[]) => void
  style?: object
}

const ClickableTags = ({ tags, handleSelect, style = {} }: Props) => {
  const [selectedTags, setselectedTags] = useState<tagsItem[]>([])

  const changetagsHandler = (e: any, tag: tagsItem) => {
    e.preventDefault()

    setselectedTags((crt) => {
      let targetTags = []
      let isChecked = crt.indexOf(tag) >= 0
      if (isChecked) {
        targetTags = [...crt].filter((tg) => tg.id !== tag.id)
      } else {
        targetTags = [...crt, tag]
      }
      if (handleSelect) handleSelect(targetTags.map((i) => i.title))
      return targetTags
    })
  }

  // if (!!!tags.length) return null
  return (
    <Space direction="horizontal" size={[4, 10]} wrap style={style}>
      {tags.map((tag) => {
        let selectedIds = selectedTags.map((crTag) => crTag.id)
        let isChecked = selectedIds.includes(tag.id)
        return (
          <Tag
            bordered={false}
            className={`ant-tag ${isChecked && "checked"}`}
            {...(isChecked && { icon: <CheckCircleIcon /> })}
            key={tag.id}
            onClick={(e) => changetagsHandler(e, tag)}
          >
            {tag.title}
          </Tag>
        )
      })}
    </Space>
  )
}

export default ClickableTags
