import { Stack } from "@mui/material";
import { Select, Typography } from "antd";
import { useState } from "react";
type MenuItemType = {
  title: string;
  value: string;
};
interface Props {
  menuItems?: MenuItemType[];
  onSelect?: (val: string) => void;
  defaultValue?: string;
  label?: string;
  loading?: boolean;
}

const DropDown = ({
  menuItems,
  onSelect,
  defaultValue,
  label,
  loading = false,
}: Props) => {
  const [value, setValue] = useState("");
  const changeDropdownHandler = (val: string) => {
    setValue(val);
    if (onSelect) onSelect(value);
  };
  return (
    <Stack sx={{ position: "relative" }}>
      {!!!value && label && (
        <Typography.Text
          style={{
            position: "absolute",
            top: "50%",
            left: 16,
            transform: "translateY(-50%)",
            zIndex: 1,
            cursor: "default",
            opacity: 0.5,
          }}
        >
          {label}
        </Typography.Text>
      )}
      <Select
        loading={loading}
        onChange={changeDropdownHandler}
        bordered={false}
        value={value}
        options={menuItems}
        style={{
          backgroundColor: "#F4F4F4",
          borderRadius: "54px",
          minWidth: 170,
          padding: "3px",
        }}
        defaultValue={defaultValue}
      />
    </Stack>
  );
};

export default DropDown;
