import { Stack, Typography } from "@mui/material"

import { ReactNode } from "react"

type Props = {
  title: string
  icon?: ReactNode
  children: ReactNode
}

export default function Section({ title, children, icon }: Props) {
  return (
    <Stack spacing={2}>
      <Typography
        component="strong"
        variant="headingBold"
        sx={{ display: "flex", alignItems: "center", gap: 2 }}
      >
        {!!icon && icon}
        {title}
      </Typography>
      {children}
    </Stack>
  )
}
