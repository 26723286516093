import { useState } from "react"

import { FormGroup, FormLabel, FormControlLabel, Checkbox } from "@mui/material"

type checkBoxItemType = {
  id: string | number
  label: string
  value: string
}

type directionType = "row" | "column" | "column-reverse" | "row-reverse"

interface Props {
  items: checkBoxItemType[]
  title?: string
  direction?: directionType
  onSelect?: (values: string[]) => void
}

const CheckBoxes = ({
  items,
  title,
  direction = "column",
  onSelect,
}: Props) => {
  const [selected, setSelected] = useState<string[]>([])

  const changeCheckboxHandler = (item: checkBoxItemType) => {
    if (!item) return
    setSelected((crt) => {
      if (crt.indexOf(item.value) >= 0) {
        let targetItems = crt.filter((s) => s !== item.value)
        if (onSelect) onSelect(targetItems)
        return targetItems
      } else {
        let targetItems = [...crt, item.value]
        if (onSelect) onSelect(targetItems)
        return targetItems
      }
    })
  }
  return (
    <FormGroup sx={{ flexDirection: direction }}>
      {!!title && <FormLabel id="checkboxes-title">{title}</FormLabel>}
      {items.map((item) => {
        const isSelected = selected.includes(item.value)
        return (
          <FormControlLabel
            key={item.id}
            label={item.label}
            value={item.value}
            control={
              <Checkbox
                checked={isSelected}
                onChange={() => changeCheckboxHandler(item)}
              />
            }
          />
        )
      })}
    </FormGroup>
  )
}

export default CheckBoxes
