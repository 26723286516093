//External import
import {
  Box,
  IconButton,
  LinearProgress,
  Modal,
  Typography,
  alpha,
  Theme,
  SxProps,
} from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CloseIcon from "@mui/icons-material/Close"
//Internal import

export type ModalGravity = "bottom" | "modal"

export interface ISharedModalProps {
  open?: boolean
  handleClose: () => void
  headerTitle?: string
  headerBack?: (() => void) | null
  forwardProps?: React.ReactElement[] | React.ReactElement
  hasOverflow?: boolean
  isLoading?: boolean
  sx?: SxProps<Theme>
  gravity?: ModalGravity
  parentSx?: SxProps
  px?: number | number[]
  py?: number | number[]
  children: React.ReactNode
}

const SharedModal: React.FC<ISharedModalProps> = ({
  children,
  open = false,
  handleClose,
  headerTitle,
  sx = {},
  px = 2,
  py = 2,
  forwardProps,
  headerBack,
  hasOverflow = true,
  parentSx = {},
  isLoading = false,
  gravity = "modal",
}) => {
  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      onClose={handleClose}
      sx={parentSx}
    >
      <Box
        className="modal"
        sx={{
          position: ["absolute"],
          top: [gravity === "modal" ? "0" : "initial", , "50%"],
          left: ["0", , "50%"],
          bottom: ["0", , "initial"],
          right: ["0", , "initial"],
          transform: ["none", , "translate(-50%, -50%)"],
          borderBottomRightRadius: [0, , "12px"],
          borderBottomLeftRadius: [0, , "12px"],
          borderTopRightRadius: [gravity === "modal" ? 0 : "12px", , "12px"],
          borderTopLeftRadius: [gravity === "modal" ? 0 : "12px", , "12px"],
          bgcolor: (t) => t.palette.common.white,
          width: headerTitle ? ["100%", , 500] : ["auto", , "600px"],
          maxWidth: "100%",
          maxHeight: ["100%", , "90%"],
          overflowY: hasOverflow ? "auto" : "hidden",
          pt: 0,
          px: 0,
          ...sx,
        }}
      >
        {isLoading && (
          <Box>
            <LinearProgress />
          </Box>
        )}

        <Box
          className="modal-content"
          sx={{
            opacity: isLoading ? 0.5 : 1,
            pointerEvents: isLoading ? "none" : "initial",
          }}
        >
          {headerTitle && (
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
                justifyContent: "space-between",
                borderColor: (t) => alpha(t.palette.common.black, 0.12),
                bgcolor: (t) => t.palette.common.white,
                position: "sticky",
                top: 0,
                zIndex: 11,
              }}
              className="header-modal"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {headerBack ? (
                  <IconButton sx={{ mr: 1 }} onClick={headerBack}>
                    <ArrowForwardIcon />
                  </IconButton>
                ) : (
                  <IconButton sx={{ mr: 1 }} onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                )}
                <Typography
                  component="h2"
                  variant="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  {headerTitle}
                </Typography>
              </Box>
              {forwardProps && forwardProps}
            </Box>
          )}
          <Box className="body-modal">{children}</Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default SharedModal
