import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import { ChangeEvent, useState } from "react"

type radioItemType = {
  id: string | number
  label: string
  value: string
}

interface Props {
  items: radioItemType[]
  title?: string
  onSelect?: (val: string) => void
  defaultValue?: string
}

const Radios = ({ items, title, onSelect, defaultValue }: Props) => {
  const [value, setValue] = useState(defaultValue || items?.[0]?.value || "")

  const changeRadioHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let targetValue = e.target.value
    setValue(targetValue)
    if (onSelect) onSelect(targetValue)
  }

  if (!items.length) return null
  return (
    <FormControl>
      {!!title && <FormLabel id="radio-group-title">{title}</FormLabel>}
      <RadioGroup
        value={value}
        onChange={changeRadioHandler}
        aria-labelledby="controlled-radio-buttons-group"
        name="controlled-radio-buttons-group-name"
      >
        {items.map((item) => {
          return (
            <FormControlLabel
              value={item.value}
              label={item.label}
              key={item.id}
              control={<Radio />}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}

export default Radios
