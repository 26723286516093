import { useAppSelector } from "@/store/root"

const useAuth = () => {
  const auth = useAppSelector((store) => store.auth)

  return {
    user: auth.user,
    isLoggedIn: !!auth.token
  }
}
export default useAuth
