import { ReactNode } from "react"
import { IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useGlobalContext } from "@/context/global-context"
import { AllVariants } from "@/theme/declarations"

interface IModalContentWrapperProps {
  title?: string
  children: ReactNode
  titleVariant?: AllVariants
}

const ModalContentWrapper: React.FunctionComponent<
  IModalContentWrapperProps
> = ({ title, children, titleVariant = "bodyBold" }) => {
  // Getting global context
  const { closeModal } = useGlobalContext()

  return (
    <Stack spacing={2} className="modal-content-wrapper">
      {Boolean(title) && (
        <Stack
          spacing={1}
          direction="row"
          justifyContent="start"
          alignItems={"center"}
          sx={{
            position: "sticky",
            top: 0,
            p: 1.5,
            zIndex: 1,
            bgcolor: (t) => t.palette.common.white,
            borderBottom: 1,
            borderColor: (t) => t.palette.divider,
          }}
          className="title-holder"
        >
          {/* Closing modal */}
          <IconButton
            onClick={() => {
              closeModal()
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* Closing modal */}
          <Typography variant={titleVariant} color="text.primary">
            {title}
          </Typography>
        </Stack>
      )}
      {children}
    </Stack>
  )
}

export default ModalContentWrapper
