import { IconType } from "."

const BlockListIcon = ({ size = 120, color = "#B3B3B3" }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 120 120"
      fill="none"
    >
      <mask
        id="mask0_5209_65633"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="120"
        height="120"
      >
        <rect
          x="0.75"
          y="0.75"
          width="118.5"
          height="118.5"
          fill={color}
          stroke="white"
          stroke-width="1.5"
        />
      </mask>
      <g mask="url(#mask0_5209_65633)">
        <path
          d="M106.532 18.4678L107.063 17.9375L106.532 18.4678C108.351 20.2866 109.25 22.4505 109.25 25V95C109.25 97.5495 108.351 99.7134 106.532 101.532C104.713 103.351 102.55 104.25 100 104.25H20C17.4505 104.25 15.2866 103.351 13.4678 101.532L12.9375 102.062L13.4678 101.532C11.649 99.7134 10.75 97.5495 10.75 95V25C10.75 22.4505 11.649 20.2866 13.4678 18.4678C15.2866 16.649 17.4505 15.75 20 15.75H100C102.55 15.75 104.713 16.649 106.532 18.4678ZM19.25 95V95.75H20H100H100.75V95V25V24.25H100H20H19.25V25V95ZM73.2822 61.4035L90.3769 44.1875L96.4394 50.25L72.75 73.9393L59.681 60.8703L65.6297 54.8154L72.2197 61.4053L72.7519 61.9375L73.2822 61.4035ZM49.25 75.75V84.25H25.75V75.75H49.25ZM49.25 55.75V64.25H25.75V55.75H49.25ZM49.25 35.75V44.25H25.75V35.75H49.25Z"
          fill={color}
          stroke="white"
          stroke-width="1.5"
        />
      </g>
    </svg>
  )
}

export default BlockListIcon
