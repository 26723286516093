import { LazyComponent, PublicRoute } from ".";
import { Navigate, createBrowserRouter } from "react-router-dom";
import OutletWrapper from "@/components/OutletWrapper";
import PrivateRoute from "./PrivateRoute";
import { Routes } from "@/routes";
import { lazy } from "react";

//wrappers
const DashboardWrapper = lazy(() => import("@/components/DashboardWrapper"));
const AuthWrapper = lazy(() => import("@/components/AuthWrapper"));

//users
const Users = lazy(() => import("@/components/pages/users"));
const SingleUser = lazy(() => import("@/components/pages/users/view"));

//posts
const Posts = lazy(() => import("@/components/pages/posts"));
const WaitingPosts = lazy(() => import("@/components/pages/posts/Waitings"));
const SinglePost = lazy(() => import("@/components/pages/posts/view"));
//categories
const Categories = lazy(() => import("@/components/pages/categories"));

//auth routes
const SignOut = lazy(() => import("@/components/pages/auth/Logout"));
const SignIn = lazy(() => import("@/components/pages/auth/Login"));
//not found
const NotFound = lazy(() => import("@/components/pages/404"));
//setting
const Settings = lazy(() => import("@/components/pages/settings"));
//tags
const Tags = lazy(() => import("@/components/pages/tags"));
//Notifications
const Notifications = lazy(() => import("@/components/pages/notifications"));
//Manage Notification
const ManageNotification = lazy(
  () => import("@/components/pages/notifications/manage")
);

const browserRouter = () => {
  const router = createBrowserRouter([
    {
      path: Routes.home,
      element: (
        <Navigate
          to={`${Routes.dashboard.index}/${Routes.dashboard.users.index}`}
        />
      ),
    },
    {
      path: Routes.dashboard.index,
      element: (
        <LazyComponent
          element={<PrivateRoute element={<DashboardWrapper />} />}
        />
      ),
      children: [
        {
          path: Routes.dashboard.index,
          element: <Navigate to={`${Routes.dashboard.users.index}`} />,
        },
        {
          path: Routes.dashboard.categories.index,
          element: <LazyComponent element={<Categories />} />,
        },
        {
          path: Routes.dashboard.tags.index,
          element: <LazyComponent element={<Tags />} />,
        },
        {
          path: Routes.dashboard.notifications.index,
          element: <LazyComponent element={<OutletWrapper />} />,
          children: [
            {
              index: true,
              element: <LazyComponent element={<Notifications />} />,
            },
            {
              path: Routes.dashboard.notifications.add,
              element: <LazyComponent element={<ManageNotification />} />,
            },
            {
              path: Routes.dashboard.notifications.view,
              element: <LazyComponent element={<ManageNotification />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.users.index,
          element: <OutletWrapper />,
          children: [
            {
              index: true,
              element: <LazyComponent element={<Users />} />,
            },
            {
              path: Routes.dashboard.users.view,
              element: <LazyComponent element={<SingleUser />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.posts.index,
          element: <OutletWrapper />,
          children: [
            {
              index: true,
              element: <LazyComponent element={<Posts />} />,
            },
            {
              path: Routes.dashboard.posts.list,
              element: <LazyComponent element={<Posts />} />,
            },
            {
              path: Routes.dashboard.posts.view,
              element: <LazyComponent element={<SinglePost />} />,
            },
            {
              path: Routes.dashboard.posts.waiting,
              element: <LazyComponent element={<WaitingPosts />} />,
            },
          ],
        },
        {
          path: Routes.dashboard.settings,
          element: <LazyComponent element={<Settings />} />,
        },
      ],
    },
    {
      path: Routes.auth.index,
      element: <LazyComponent element={<AuthWrapper />} />,
      children: [
        {
          path: Routes.auth.login,
          element: (
            <LazyComponent element={<PublicRoute element={<SignIn />} />} />
          ),
        },
        {
          path: Routes.auth.logout,
          element: (
            <LazyComponent element={<PrivateRoute element={<SignOut />} />} />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <LazyComponent element={<NotFound />} />,
    },
  ]);

  return router;
};

export default browserRouter;
