import axios from "axios"
import store from "@/store/root"
import { API_URL } from "@/constants"

export const api = axios.create({
  baseURL: API_URL,
})

store.subscribe(() => {
  api.defaults.headers["Authorization"] = store.getState().auth.token
})
