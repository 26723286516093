import { useCallback } from "react"
import SearchBox from "../SearchBox"

interface Props {
  changeTurtle?: (val: string) => void
  changeHandler?: (val: string) => void
}

let timeoutId: NodeJS.Timeout

const TurtleSearchBox = ({ changeHandler, changeTurtle }: Props) => {
  const changeTurtleSearchHandler = useCallback(
    (search: string) => {
      const handleSearchTimeout = () => {
        if (changeTurtle) changeTurtle(search)
      }
      clearTimeout(timeoutId)
      timeoutId = setTimeout(handleSearchTimeout, 1000)
      changeHandler(search)
    },
    [changeHandler, changeTurtle]
  )

  return <SearchBox handleChange={changeTurtleSearchHandler} />
}

export default TurtleSearchBox
