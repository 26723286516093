//Internal import
import common from "./common.json"

/**
 * All different namespaces (each will be stored in a separate file) are loaded and exported from here
 * @example common,etc.
 */
const resources = {
  common
}

export default resources
