//Internal import

import common from "./common.json"
import validation from "./validation.json"
/**
 * All different namespaces (each will be stored in a separate file) are loaded and exported from here
 * @example common,etc.
 */
const resources = {
  common,
  validation,
}

export default resources
