import "./i18n/i18n"
import "./global.css"

import store, { persistor } from "@/store/root"
import { QueryClient, QueryClientProvider } from "react-query"

import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import { RouterConfig } from "./routes"

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <RouterConfig />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  )
}

export default App
