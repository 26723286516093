import React from "react";
import ModalContentWrapper from "../Modal/ModalContentWrapper";
import { Button, Space, Typography } from "antd";
import { useGlobalContext } from "@/context/global-context";
import { t } from "i18next";

const { Title } = Typography;
interface Props {
  title: string;
  loading?: boolean;
  description: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  confirmText?: string;
}

const PromptBox = ({
  title,
  description,
  onCancel,
  onSubmit,
  confirmText = t("ok"),
  loading = false,
}: Props) => {
  const { closeModal } = useGlobalContext();

  const closeBoxHandler = () => {
    if (onCancel) onCancel();
    closeModal();
  };
  return (
    <ModalContentWrapper title={title}>
      <Space
        direction="vertical"
        size={24}
        style={{ width: "100%", padding: "24px", paddingTop: 0 }}
        styles={{ item: { width: "100%" } }}
      >
        <Title style={{ margin: 0 }} level={5}>
          {description}
        </Title>
        <Space
          style={{ width: "100%" }}
          size={16}
          styles={{
            item: {
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            },
          }}
        >
          <Button
            style={{ width: "100%", borderRadius: "44px", minHeight: 40 }}
            onClick={closeBoxHandler}
          >
            {t("cancel")}
          </Button>
          <Button
            style={{ width: "100%", borderRadius: 44, minHeight: 40 }}
            loading={loading}
            type="primary"
            danger
            onClick={onSubmit}
          >
            {confirmText}
          </Button>
        </Space>
      </Space>
    </ModalContentWrapper>
  );
};

export default PromptBox;
