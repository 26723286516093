import { ReactNode } from "react";
import MyTable, { ITableItems } from "../Table";
import { calcPageCount, urlWithQueryParams } from "@/utils";
import { IWithPaginationProps } from "@/hoc/with-pagination";
import { Stack, Typography } from "@mui/material";
import { WithPagination } from "@/hoc";
import { useFetch } from "@/hooks";

interface IDynamicListingProps extends IWithPaginationProps {
  endpoint?: string;
  children: (
    items: ITableItems[],
    loading: boolean,
    onUpdate: Function,
    pagination?: ReactNode
  ) => ReactNode;
  queries?: object;
  perPage?: number;
  title?: string;
  filters?: ReactNode;
  actionNode?: ReactNode;
}

const DynamicListing: React.FunctionComponent<IDynamicListingProps> = ({
  endpoint,
  page,
  renderPagination,
  queries = {},
  children,
  title = "",
  filters,
  actionNode,
  perPage = 16,
}) => {
  const {
    data: fetchedData,
    loading: fetchLoading,
    renderAgain,
  } = useFetch<any>(
    urlWithQueryParams(endpoint, {
      ...queries,
      perPage: perPage,
      page,
    })
  );

  const showTableHeading = !!actionNode || !!title || !!filters;
  const fetchedDataItems = (fetchedData && fetchedData.items) || [];

  const totalItems = (fetchedData && fetchedData.total) || 0;

  const pageCount = calcPageCount(totalItems, perPage);

  return (
    <Stack direction="column" height={"100%"}>
      {showTableHeading && (
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          p={2}
          py={3}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={4}
          >
            {title && <Typography variant="headerBold">{title}</Typography>}
            {filters && filters}
          </Stack>
          {actionNode && actionNode}
        </Stack>
      )}

      <Stack height={"100%"} justifyContent={"space-between"}>
        {children(
          fetchedDataItems,
          fetchLoading,
          renderAgain,
          pageCount > 1 && renderPagination(pageCount)
        )}
      </Stack>
    </Stack>
  );
};

export default WithPagination(DynamicListing);
