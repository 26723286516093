import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { UserType } from "@/types/user"

type AuthState = {
  user?: UserType | null
  token?: string
}

const initialState: AuthState = {
  user: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserType>) {
      state.user = action.payload
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    signOut(state) {
      state.user = null
      state.token = null
    },
  },
})

export const { setUser, signOut, setToken } = authSlice.actions

export default authSlice.reducer
