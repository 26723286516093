import { Switch } from "antd";
import "./style.css";
type Props = {
  loading?: boolean;
} & any;

const CustomSwitch = ({ loading = false, ...props }: Props) => {
  return <Switch loading={loading} {...props} />;
};

export default CustomSwitch;
