import RangeCalendar from "rc-calendar/lib/RangeCalendar"
import enUS from "rc-calendar/lib/locale/en_US"
import moment, { Moment } from "moment"
import "rc-calendar/assets/index.css"

type selectedDateType = {
  startDate: string | Date
  secondDate: string | Date
}
interface Props {
  handleSelectDate?: (selectedDate: selectedDateType) => void
}

const RangeDateCalendar = ({ handleSelectDate }: Props) => {
  const changeDateHandler = (value: Moment) => {
    let startDate: Date
    let secondDate: Date
    if (value?.[0]) {
      startDate = new Date(value?.[0])
    }
    if (value?.[1]) {
      secondDate = new Date(value?.[1])
    }
    if (startDate && secondDate) {
      if (handleSelectDate) handleSelectDate({ startDate, secondDate })
    }
  }

  function disabledDate(current) {
    const date = moment()
    date.hour(0)
    date.minute(0)
    date.second(0)
    return current.isBefore(date) // can not select days before today
  }

  return (
    <RangeCalendar
      style={{
        margin: "auto",
        width: "fit-content",
        boxShadow: "none",
        border: "none",
      }}
      showToday={false}
      dateInputPlaceholder={["start", "end"]}
      locale={enUS}
      showOk={false}
      format={"YYYY-MM-DD"}
      onChange={changeDateHandler}
      disabledDate={disabledDate}
    />
  )
}

export default RangeDateCalendar
