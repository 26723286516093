import { Space, Tag } from "antd"
import "./style.css"
import { MouseEvent, ReactNode } from "react"

type antTagType = {
  closeIcon?: ReactNode | null
  color?: string
  icon?: ReactNode | null
  closable?: boolean
  bordered?: boolean
}

export type TagItemType = {
  id: string | number
  title: string
}
type Props = {
  style?: object
  items: TagItemType[]
  onCloseHandler?: (e: MouseEvent<HTMLElement>, tag: TagItemType) => void
} & antTagType

const TagsGroup = ({
  style = {},
  items = [],
  onCloseHandler,
  ...props
}: Props) => {
  const closeTagHandler = (e: MouseEvent<HTMLElement>, tag: TagItemType) => {
    if (onCloseHandler) onCloseHandler(e, tag)
  }
  return (
    <Space size={[8, "middle"]} wrap style={style}>
      {items.map((tag) => {
        return (
          <Tag
            key={tag.id}
            bordered={false}
            {...props}
            {...(onCloseHandler && { onClose: (e) => closeTagHandler(e, tag) })}
          >
            {tag.title}
          </Tag>
        )
      })}
    </Space>
  )
}

export default TagsGroup
