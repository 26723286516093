const routes = {
  home: "/",
  dashboard: {
    index: "/dashboard",
    users: {
      index: "users",
      view: "view/:id",
    },
    categories: {
      index: "categories",
      view: ":id",
    },

    tags: {
      index: "tags",
      view: ":id",
    },
    notifications: {
      index: "notifications",
      view: ":id",
      add: "add",
    },
    trending: {
      index: "trending",
      view: ":view",
    },
    posts: {
      index: "posts",
      list: "list",
      waiting: "waiting",
      view: ":view",
    },
    settings: "settings",
  },
  auth: {
    index: "/auth",
    login: "login",
    logout: "logout",
    forget_password: "forget_password",
    reset_password: "reset_password",
  },
};

export default routes;
