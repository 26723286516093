import { ReactNode, useMemo, useState } from "react";
import {
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
type MenuItem = {
  title: string;
  href: string;
  icon?: ReactNode;
  children?: MenuItem[];
};

type MenuItemProps = {
  menuItem: MenuItem;
};
function SidebarMenuItem({ menuItem }: MenuItemProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen((crt) => !crt);
  };

  const isActiveRoute = useMemo(() => {
    if (location.pathname === menuItem.href) return true;
    return false;
  }, [location.pathname, menuItem.href]);

  function menuItemClickHandler() {
    if (!!!menuItem?.href) return null;

    navigate(menuItem.href);
  }

  let menuStyle: SxProps<Theme> = {
    [`&:hover`]: {
      backgroundColor: (t) => alpha(t.palette.primary.main, 0.03),
    },
  };

  if (isActiveRoute)
    menuStyle = {
      backgroundColor: (t) => alpha(t.palette.primary.main, 0.03),
      borderLeft: 3,
      borderColor: (t) => t.palette.primary.main,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      [`& .menu-icon-holder svg`]: {
        fill: (t) => t.palette.primary.main,
      },
      [`& .menu-item-title`]: {
        color: (t) => t.palette.primary.main,
        fontWeight: "Bold",
      },
    };

  return (
    <>
      <Stack
        p={1.5}
        direction="row"
        spacing={2}
        sx={{
          borderRadius: 2,
          cursor: "pointer",
          ...menuStyle,
        }}
        onClick={menuItemClickHandler}
        justifyContent={"space-between"}
        alignItems={"center"}
        className={`menu-item ${isActiveRoute ? "active" : "not-active"}`}
      >
        <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
          {menuItem?.icon && (
            <Stack className="menu-icon-holder">{menuItem.icon}</Stack>
          )}
          <Typography className="menu-item-title">{menuItem.title}</Typography>
        </Stack>
        {menuItem?.children && menuItem.children.length > 0 && (
          <IconButton onClick={toggleMenu} size="small">
            {isOpen ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </IconButton>
        )}
      </Stack>
      {menuItem?.children && isOpen && (
        <SidebarMenu
          menuItems={menuItem.children}
          sx={{
            py: 0,
            borderLeft: 2,
            borderColor: (t) => alpha(t.palette.common.black, 0.08),
            [`& p`]: {
              fontSize: 14,
            },
            [`& .active`]: {
              backgroundColor: "transparent",
            },
          }}
        />
      )}
    </>
  );
}

type Props = {
  menuItems: MenuItem[];
  sx?: SxProps<Theme>;
};

export default function SidebarMenu({ menuItems = [], sx = {} }: Props) {
  if (menuItems.length === 0) return null;

  return (
    <Stack
      className="menu-sidebar"
      component="nav"
      sx={{ width: "100%", ...sx }}
      gap={1}
    >
      {menuItems.map((menuItem, key) => (
        <SidebarMenuItem key={key} menuItem={menuItem} />
      ))}
    </Stack>
  );
}
