import { RightOutlined } from "@ant-design/icons"
import { Breadcrumb, Typography } from "antd"
import { Link } from "react-router-dom"
const { Text } = Typography

type routeItem = { title: string; path?: string }
interface Props {
  items?: routeItem[]
}

const Breadcrumbs = ({ items }: Props) => {
  const renderBreadcrumb = (
    route: routeItem,
    params: object,
    items: routeItem[],
    paths: string[]
  ) => {
    const last = items.indexOf(route) === items.length - 1
    return last ? (
      <Text type="secondary" style={{ textTransform: "capitalize" }}>
        {route.title}
      </Text>
    ) : (
      <Link to={`/${paths.join("/")}`}>
        <Text strong style={{ textTransform: "capitalize" }}>
          {route.title}
        </Text>
      </Link>
    )
  }
  return (
    <Breadcrumb
      separator={<RightOutlined style={{ fontSize: 12, color: "black" }} />}
      itemRender={renderBreadcrumb}
      items={items}
    />
  )
}

export default Breadcrumbs
