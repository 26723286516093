// built-in import
import * as React from "react"

// Internal import
import {
  Box,
  Tab,
  Tabs,
  Typography,
  alpha,
  SxProps,
  Theme,
} from "@mui/material"

/**
 * Reusable tabs component, dynamic tab with our unique styles!
 */

export interface ITab {
  label: string | React.ReactNode
  children: React.ReactElement
  tabIcon?: string | null | React.ReactElement
}
export interface IMyTabsProps {
  tabId: string
  items: ITab[]
  active?: number
  title?: string
  sx?: SxProps<Theme>
  sxParent?: SxProps<Theme>
  stickyTab?: boolean
}
export interface IMyTabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  tabId: string
}
/**
 * Add id and ... by index number
 */
function addSomeMetaToTab(tabId: string = "single-tab", index: number) {
  return {
    id: `${tabId}-${index}`,
    "aria-controls": `${tabId}-${index}`,
  }
}

/**
 * Tab Panel, holder of each tab's children!
 */
function TabPanel(props: IMyTabPanelProps) {
  const { children, value, index, tabId, ...other } = props

  return (
    <Box
      sx={{ height: "100%" }}
      role="tabpanel"
      className="tabpanel-container"
      hidden={value !== index}
      id={`${tabId}-${index}`}
      aria-labelledby={`${tabId}-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 0, height: "100%" }}>{children}</Box>}
    </Box>
  )
}

const MyTabs: React.FunctionComponent<IMyTabsProps> = (props) => {
  const {
    tabId,
    items = [],
    sx = {},
    title = "",
    sxParent = {},
    stickyTab = false,
    active = 0,
  } = props

  const [activeTab, setActiveTab] = React.useState(active)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  return (
    <Box
      sx={{
        height: "100%",
        ...sxParent,
        ...(stickyTab
          ? {
              [`& .header-tabs`]: {
                position: "sticky",
                top: 0,
                zIndex: 10,
              },
              [`& .MuiTabs-root`]: {
                bgcolor: (t) =>
                  `${alpha(t.palette.common.white, 0.8)} !important`,
                mb: 0,
                pb: 0,
                [`& .MuiTab-root`]: {
                  boxShadow: "none !important",
                },
                backdropFilter: `blur(5px)`,
                padding: "8px",
                boxShadow: `0 2px 8px rgb(0 0 0 / 12%)`,
                borderRadius: 0,
              },
            }
          : {
              [`& .MuiTabs-root`]: {
                mb: 0,
                pb: 0,
              },
            }),
      }}
    >
      <Box
        className="header-tabs"
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid",
          borderColor: (t) => alpha(t.palette.common.black, 0.12),
        }}
      >
        {title ? (
          <Box
            sx={{
              display: "flex",
              px: 2,
            }}
          >
            <Typography variant={"headerBold"}>{title}</Typography>
          </Box>
        ) : null}
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label={tabId}
          sx={{
            mb: 2,
            width: "100%",
            [`& .MuiTab-root`]: {
              "&.Mui-selected": {
                fontWeight: "bold",
              },
              maxWidth: "initial",
              //   width: ["100%", `${100 / items.length}%`],
              width: "auto",
              minHeight: "initial",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              py: 2,
              fontWeight: "500",
              fontSize: 16,
              flexGrow: [1, 0],
            },
            ...sx,
          }}
        >
          {items.map((item, key) => {
            return (
              <Tab
                disableRipple
                label={item.label}
                {...addSomeMetaToTab(tabId, 0)}
                icon={item.tabIcon ? item.tabIcon : <></>}
                iconPosition={"start"}
                key={key}
                onClick={(e) => e.preventDefault()}
              />
            )
          })}
        </Tabs>
      </Box>
      {items.map((item, key) => {
        return (
          <TabPanel tabId={tabId} key={key} value={activeTab} index={key}>
            {item.children}
          </TabPanel>
        )
      })}
    </Box>
  )
}

export default MyTabs
