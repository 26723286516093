import React, { ChangeEvent, useState } from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
} from "@mui/material";
import { SxProps, Theme } from "@mui/material";

import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { MyTableSkeleton } from "./skeleton";
import { ReactElement } from "react";

export interface ITableItems {
  title?: string;
  render?: (item: any, index?: number) => ReactElement | string | number;
  align?: any;
}

export interface ITableProps {
  endButton?: React.ReactElement;
  tableHeadItems?: ITableItems[];
  items: any;
  selectable?: boolean;
  onChangeSelectedIds?: (items: any) => void;
  totalItems?: number;
  loading?: boolean;
  sxProps?: SxProps<Theme>;
  activeIndex?: number;
}

const MyTable: React.FC<ITableProps> = ({
  tableHeadItems = [],
  items,
  selectable = false,
  onChangeSelectedIds,
  loading = false,
  sxProps = {},
  activeIndex,
}): React.ReactElement => {
  //Collect item id
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  //Fire onChangeSelected
  const updateSelectedEvent = (items: number[]) => {
    if (onChangeSelectedIds && typeof onChangeSelectedIds === "function")
      onChangeSelectedIds(items);
  };
  //Function to store item id
  const handleSelectId = (id: number) => {
    if (selectedIds.indexOf(id) !== -1) {
      const newIds = selectedIds.filter((item) => item !== id);
      setSelectedIds(newIds);
      //Update event listener
      updateSelectedEvent(newIds);
    } else {
      const newIds = [...selectedIds, id];
      setSelectedIds(newIds);
      //Update event listener
      updateSelectedEvent(newIds);
    }
  };

  const mainTableContainer = (
    <TableContainer sx={{ mt: "0 !important", height: "100%" }}>
      <Table
        sx={{
          "& :last-child td, &:last-child th :not(thead)": {
            border: 0,
          },
          ...sxProps,
        }}
      >
        <TableHead
          sx={{
            borderBottom: 1,
            bgcolor: (t) => alpha(t.palette.primary.main, 0.05),
            "& tr th": {
              whiteSpace: "nowrap",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "bold",
              color: (t) => alpha(t.palette.common.black, 0.6),
              p: 1,
              py: 1.5,
            },
          }}
        >
          <TableRow>
            {/** Selectable th */}
            {selectable && <TableCell>#</TableCell>}
            {/** Selectable th */}
            {tableHeadItems.map((item, key) => (
              <TableCell
                key={key}
                style={{
                  textAlign: item?.align || "left",
                }}
              >
                {item.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& td": {
              whiteSpace: "nowrap",
              textAlign: "center",
              verticalAlign: "top",
              p: 1.5,
            },
          }}
        >
          {loading ? (
            <MyTableSkeleton
              selectable={selectable}
              tableHeadItems={tableHeadItems}
            />
          ) : items.length === 0 ? (
            <TableRow>
              <TableCell colSpan={tableHeadItems.length + 5}>
                <Typography>Nothing was found</Typography>
              </TableCell>
            </TableRow>
          ) : (
            items.map((item, rowKey) => {
              //Selected value
              const isSelectedCheckbox = selectedIds.indexOf(item?.id) !== -1;

              const isActive = activeIndex === rowKey;

              return (
                <TableRow
                  key={rowKey}
                  sx={{
                    borderBottom: "1px solid",
                    borderBottomColor: (t) =>
                      alpha(t.palette.common.black, 0.12),
                    ...(isActive
                      ? {
                          backgroundColor: (t) =>
                            alpha(t.palette.primary.main, 0.06),
                          [`& *`]: {
                            color: (t) => t.palette.primary.main,
                          },
                        }
                      : {}),
                  }}
                >
                  {/** Selectable checkbox */}
                  {selectable && (
                    <TableCell>
                      <Checkbox
                        checked={isSelectedCheckbox}
                        onChange={(e: CheckboxChangeEvent) =>
                          handleSelectId(item?.id)
                        }
                      />
                    </TableCell>
                  )}
                  {/** Selectable checkbox */}
                  {tableHeadItems.map((tdItem, tdKey) => {
                    return (
                      <TableCell
                        key={tdKey}
                        style={{
                          lineHeight: 2,
                          textAlign: tdItem?.align || "left",
                        }}
                      >
                        {tdItem.render(item, rowKey)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return <>{mainTableContainer}</>;
};

export default MyTable;
