import { useEffect, useState } from "react";
import DropDown from "./DropDown";
import { useLoading } from "@/hooks";
import { api } from "@/services/http/api";
import { t } from "i18next";

type Props = {
  onSelect?: (value: string) => void;
};

export default function CategoriesDropdown({ onSelect }: Props) {
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    startLoading();
    api
      .get("/categories")
      .then((res) => {
        setCategories(res.data?.items || []);
        stopLoading();
      })
      .catch((err) => {
        stopLoading();
      });
  }, []);

  return (
    <DropDown
      menuItems={[]}
      label={t("category")}
      loading={isLoading}
      onSelect={onSelect}
    />
  );
}
