import { DatePicker } from "antd"
import { useState } from "react"
import type { Dayjs } from "dayjs"
import dayjs from "dayjs"
import "./style.css"

interface Props {
  onDateValue?: (val: string | Date) => void
  onStringDateValue?: (val: string) => void
  placeholder?: string
  defaultValue?: Dayjs
}

const Datepicker = ({
  onDateValue,
  onStringDateValue,
  placeholder = "Select Date...",
  defaultValue,
}: Props) => {
  const [value, setValue] = useState<Dayjs | null>(defaultValue || null)

  const changeDateHandler = (date: any, dateString: string) => {
    if (!!date) setValue(date)
    if (!!date && !!onDateValue) {
      let currentDate = new Date(date)
      onDateValue(currentDate)
    }
    if (!!dateString && !!onStringDateValue) {
      onStringDateValue(dateString)
    }
  }
  return (
    <DatePicker
      size="large"
      placeholder={placeholder}
      value={value}
      onChange={changeDateHandler}
    />
  )
}

export default Datepicker
