import { Navigate } from "react-router-dom"
import { ReactNode } from "react"
import { Routes } from "."
import { useAuth } from "@/hooks"

type Props = {
  element: ReactNode
}

export default function PublicRoute({ element }: Props) {
  const { isLoggedIn } = useAuth()

  if (isLoggedIn) return <Navigate to={`${Routes.dashboard.index}`} />

  return <>{element}</>
}
