import { ReactNode } from "react"
import { Stack, Typography } from "@mui/material"

interface Props {
  title: string
  icon?: ReactNode
}

const NotFoundItems = ({ title, icon }: Props) => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
      {!!icon && icon}
      <Typography
        variant="headingMedium"
        color={"text.secondary"}
        textAlign={"center"}
      >
        {title}
      </Typography>
    </Stack>
  )
}

export default NotFoundItems
