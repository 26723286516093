import { UserType } from "@/types/user";
import { Link } from "react-router-dom";

type Props = {
  user: UserType;
};
export default function UserLabel({ user }: Props) {
  if (!user) return null;

  return (
    <Link
      to={`/dashboard/users/view/${user.id}`}
      style={{ textTransform: "capitalize" }}
    >
      {user.fullname}
    </Link>
  );
}
