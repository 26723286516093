import * as React from "react"

import { Stack, SxProps, Typography } from "@mui/material"

import { AllVariants } from "@/theme/declarations"

interface ITitleWithValueProps {
  title: string
  hint?: string
  hasDoubleDot?: boolean
  value: string | React.ReactNode
  justifyContent?:
    | "flex-end"
    | "flex-start"
    | "center"
    | "space-between"
    | ("flex-end" | "flex-start" | "center" | "space-between")[]
  alignItems?:
    | "flex-end"
    | "flex-start"
    | "center"
    | "space-between"
    | ("flex-end" | "flex-start" | "center" | "space-between")[]
  direction?: "row" | "column" | "row-reverse" | "column-reverse" | any
  variantTitle?: AllVariants
  variantValue?: AllVariants
  spacing?: number | string
  sxProps?: SxProps
}

const TitleWithValue: React.FunctionComponent<ITitleWithValueProps> = ({
  title,
  value,
  hint,
  direction = "column",
  justifyContent = "flex-start",
  hasDoubleDot = true,
  alignItems = "start",
  variantTitle = "subtitleMedium",
  variantValue = "bodyRegular",
  spacing = 1,
  sxProps,
}) => {
  return (
    <Stack
      direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
      width={"100%"}
      spacing={spacing}
      sx={sxProps}
      className="title-with-value"
    >
      <Typography
        color={"text.secondary"}
        variant={variantTitle}
        whiteSpace={"nowrap"}
      >
        {title}
        {hasDoubleDot ? ":" : ""}
      </Typography>
      {typeof value === ("string" || "number") ? (
        <Typography
          color={"text.primary"}
          variant={variantValue}
          whiteSpace={"nowrap"}
        >
          {value}
        </Typography>
      ) : (
        <>{value}</>
      )}
      {!!hint && (
        <Typography variant="smallLight" color="grey">
          {hint}
        </Typography>
      )}
    </Stack>
  )
}

export default TitleWithValue
