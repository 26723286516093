import { Box, Pagination, alpha } from "@mui/material"
import { useEffect, useState, ReactElement } from "react"

const defaultPage = 1

export interface IWithPaginationProps {
  page: number
  renderPagination: (count: number) => ReactElement
  setPage: React.Dispatch<React.SetStateAction<number>>
}

function withPagination<T extends IWithPaginationProps = IWithPaginationProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const ComponentWithPagination = (
    props: Omit<T, keyof IWithPaginationProps>
  ) => {
    //Pagination
    const [page, setPage] = useState(defaultPage)
    const handleChangePage = (
      event: React.ChangeEvent<unknown> | null,
      newPage: number
    ) => {
      setPage(newPage)
    }

    const renderPagination = (count: number) => {
      return count ? (
        <Pagination
          className="MyPagination"
          sx={{
            display: "flex",
            my: 5,
            mb: 0,
            justifyContent: "center",
            alignItems: "start",
            ["& .MuiPagination-ul"]: {
              p: 2,
              borderRadius: "8px",
            },
            [`& .Mui-selected`]: {
              bgcolor: (t) => `${t.palette.primary.main} !important`,
              color: (t) => `${t.palette.common.white} !important`,
            },
          }}
          count={count}
          color="primary"
          onChange={handleChangePage}
          page={page}
        />
      ) : null
    }

    useEffect(() => {
      setPage(defaultPage)
    }, [])

    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <WrappedComponent
          {...(props as T)}
          page={page}
          renderPagination={renderPagination}
          setPage={setPage}
        />
      </Box>
    )
  }

  return ComponentWithPagination
}

export default withPagination
