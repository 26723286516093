type withDateType = "with-time" | "none"

const convertDate = (date: string, withtime: withDateType = "none") => {
  if (!!!date) return "no date"
  const currentDate = new Date(date)
  let finalDate = `${currentDate.getDate()} ${currentDate.toLocaleString(
    "en-us",
    {
      month: "short",
      year: "numeric",
    }
  )} `
  if (withtime === "with-time")
    finalDate += `at ${currentDate.getHours()}:${currentDate.getMinutes()}`
  return finalDate
}

export default convertDate
