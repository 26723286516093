import { CssBaseline, ThemeProvider } from "@mui/material"
import { useEffect, useState } from "react"

import { RouterProvider } from "react-router-dom"
import { browserRouter } from "."
import { muiTheme } from "@/theme/mui"
import { useAppSelector } from "@/store/root"
import { ConfigProvider, theme } from "antd"
import { GlobalContextProvider } from "@/context"

export default function RouterConfig() {
  const router = browserRouter()
  let themeMode = useAppSelector((store) => store.settings.mode)

  const [themeModeState, setThemeModeState] = useState(null)

  useEffect(() => {
    if (themeMode === "system") {
      const darkMatches = window.matchMedia("(prefers-color-scheme: dark)")

      setThemeModeState(darkMatches.matches ? "dark" : "light")
      darkMatches.addEventListener("change", (event) => {
        if (themeMode === "system")
          setThemeModeState(event.matches ? "dark" : "light")
      })
    } else {
      setThemeModeState(themeMode)
    }
  }, [themeMode])

  const { darkAlgorithm, defaultAlgorithm } = theme
  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#002B9A" },
        algorithm: themeModeState === "dark" ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <ThemeProvider theme={muiTheme(themeModeState)}>
        <GlobalContextProvider>
          <CssBaseline />
          <RouterProvider router={router} />
        </GlobalContextProvider>
      </ThemeProvider>
    </ConfigProvider>
  )
}
