import { SearchOutlined } from "@ant-design/icons"
import { Input } from "antd"
import { ChangeEvent } from "react"
interface Props {
  placeholder?: string
  handleChange?: (val: string) => void
}

const SearchBox = ({ placeholder = "Search in List", handleChange }: Props) => {
  const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value
    if (handleChange) handleChange(targetValue)
  }
  return (
    <Input
      placeholder={placeholder}
      bordered={false}
      onChange={changeInputHandler}
      size="large"
      style={{
        backgroundColor: "#F4F4F4",
        borderRadius: "54px",
      }}
      styles={{
        prefix: {
          marginRight: "8px",
          color: "#898989",
        },
        input: {
          outline: "none",
        },
      }}
      prefix={<SearchOutlined />}
    />
  )
}

export default SearchBox
